import React from "react";
import * as ReactModal from "react-modal";
// @see https://reactcommunity.org/react-modal/#documentation
import IconCross from "app/pages/.shared/static/icons/IconCross";
import ButtonIcon from "app/pages/.shared/ButtonIcon/ButtonIcon.jsx";
import PropTypes from "prop-types";

import "./Modal.scss";

const style = {
	content: {
		border: "none",
		background: "#ffffff",
		overflow: "hidden",
	},
};

const Modal = ({ opened, handleClose, title, children }) => {
	return (
		<ReactModal
			isOpen={opened}
			onRequestClose={handleClose}
			style={style}
			className="modal__background"
			overlayClassName="modal__overlay"
			ariaHideApp={false}
		>
			<div className="modal__opacity-overlay" onClick={handleClose} />
			<div className="modal__paper">
				<div className="modal__header">
					<div className="modal__icon-container" />
					<div className="modal__title">{title}</div>
					<div className="modal__icon-container">
						<ButtonIcon className="modal__close_icon" onClick={handleClose}>
							<IconCross />
						</ButtonIcon>
					</div>
				</div>
				<div className="modal__content">{children}</div>
			</div>
		</ReactModal>
	);
};

Modal.propTypes = {
	opened: PropTypes.bool,
	handleClose: PropTypes.func,
	title: PropTypes.any,
	children: PropTypes.any,
};

export default Modal;
