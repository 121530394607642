import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { STRATEGY } from "app/constants";
import RelativeLink from "app/pages/.shared/RelativeLink";
import IconUnlock from "app/pages/.shared/IconAwesomeUnlock";
import { FormattedMessage } from "react-intl";
import DotNotification from "app/pages/.shared/DotNotification/DotNotification";
import AmountContainer from "app/pages/.shared/AmountContainer";
import NewBadge from "app/pages/Header/conditionnalComponents/NewBadge";
import IconLogout from "app/pages/.shared/static/icons/IconLogout";
import { strategyShape } from "app/utils/propTypes";
import SvgIconMagnifyingGlassBold from "app/pages/.shared/IconMagnifyingGlassBold";

const FlashSaleMenuAccount = ({
	logout,
	onItemClick,
	creditsAmount,
	hideCreditNotification,
	creditNotesAmount,
	hideCreditNoteNotification,
	userIsConnected,
	enableSponsorship,
	enableCredits,
	isWhiteLabel,
	strategy,
	partnercode,
	showCreditsNotification,
	showCreditNotesNotification,
	shouldDisplayCreditNotesEntry,
	showCustomerRequestNotification,
}) => {
	const handleLogout = useCallback(() => {
		logout();
		onItemClick();
	}, []);

	const handleCreditMenuClick = useCallback(() => {
		if (creditsAmount > 0) {
			hideCreditNotification();
		}
		onItemClick();
	}, [creditsAmount]);

	const handleCreditNoteMenuClick = useCallback(() => {
		if (creditNotesAmount > 0) {
			hideCreditNoteNotification();
		}
		onItemClick();
	}, [creditNotesAmount]);

	const showCreditDotNotification = creditsAmount > 0 && showCreditsNotification;
	const showCreditNoteDotNotification = creditNotesAmount > 0 && showCreditNotesNotification;

	return (
		<nav className="flashsale-menu-account">
			<ul className="flashsale-menu-account__nav">
				<li className="flashsale-menu-account__nav-item flashsale-menu-account__nav-item--bold">
					<RelativeLink to={{ pathname: "/" }} onClick={onItemClick}>
						<SvgIconMagnifyingGlassBold
							height={20}
							width={20}
							style={{ marginRight: "10px" }}
						/>
						<FormattedMessage id="menu.item.search.trip" />
					</RelativeLink>
				</li>
				{strategy !== STRATEGY.PRODUCT_FIRST && (
					<li className="flashsale-menu-account__nav-item flashsale-menu-account__nav-item--bold">
						<RelativeLink to={{ pathname: "/listing" }} onClick={onItemClick}>
							<IconUnlock height={20} width={20} style={{ marginRight: "10px" }} />
							<FormattedMessage id="menu.mobile.flashsales" />
						</RelativeLink>
					</li>
				)}
				<li className="flashsale-menu-account__nav-item">
					<RelativeLink to={{ pathname: "/account/profile" }} onClick={onItemClick}>
						<FormattedMessage id="menu.item.my.profile" />
					</RelativeLink>
				</li>
				<li className="flashsale-menu-account__nav-item">
					<RelativeLink to={{ pathname: "/account/mybookings" }} onClick={onItemClick}>
						<FormattedMessage id="menu.item.my.bookings" />
						{showCustomerRequestNotification && <DotNotification />}
					</RelativeLink>
				</li>
				{enableCredits && (
					<li className="flashsale-menu-account__nav-item">
						<RelativeLink
							to={{
								pathname: "/account/mycredits",
							}}
							onClick={handleCreditMenuClick}
						>
							<FormattedMessage
								id={
									creditsAmount > 0
										? "menu.item.my.credits.with.amount"
										: "menu.item.my.credits"
								}
								tagName="span"
								values={{
									creditAmount: (
										<AmountContainer
											prefix="("
											suffix=")"
											amount={creditsAmount}
										/>
									),
								}}
							/>
							{showCreditDotNotification && <DotNotification />}
						</RelativeLink>
					</li>
				)}
				{shouldDisplayCreditNotesEntry && (
					<li className="flashsale-menu-account__nav-item">
						<RelativeLink
							to={{ pathname: "/account/myrefunds" }}
							onClick={handleCreditNoteMenuClick}
						>
							<FormattedMessage
								id={
									creditNotesAmount > 0
										? "menu.item.my.refunds.with.amount"
										: "menu.item.my.refunds"
								}
								values={{
									refundAmount: (
										<AmountContainer
											prefix="("
											suffix=")"
											amount={creditNotesAmount}
										/>
									),
								}}
								tagName="span"
							/>
							{showCreditNoteDotNotification && <DotNotification />}
						</RelativeLink>
					</li>
				)}
				{(isWhiteLabel || userIsConnected) && (
					<li className="flashsale-menu-account__nav-item">
						<RelativeLink
							to={{
								pathname: "/account/subscriptions",
							}}
							onClick={onItemClick}
						>
							<FormattedMessage id="menu.item.my.subscriptions" />
						</RelativeLink>
					</li>
				)}
				{(isWhiteLabel || userIsConnected) && (
					<li className="flashsale-menu-account__nav-item">
						<RelativeLink to={{ pathname: "/account/security" }} onClick={onItemClick}>
							<FormattedMessage id="menu.item.security" />
						</RelativeLink>
					</li>
				)}
				{enableSponsorship && (
					<li className="flashsale-menu-account__nav-item">
						<RelativeLink
							to={{
								pathname: "/account/sponsorship",
							}}
							onClick={onItemClick}
						>
							<FormattedMessage id="menu.item.sponsorship" />
							<NewBadge partnerCode={partnercode} />
						</RelativeLink>
					</li>
				)}
				<li className="flashsale-menu-account__nav-item">
					<RelativeLink
						rel="noopener noreferrer"
						target="_blank"
						to={{ pathname: "/faq" }}
						onClick={onItemClick}
					>
						<FormattedMessage id="menu.item.help" />
					</RelativeLink>
				</li>
			</ul>
			{isWhiteLabel &&
				strategy !== STRATEGY.PRODUCT_FIRST &&
				strategy !== STRATEGY.NAVIGATION_FIRST &&
				userIsConnected && (
					<div className="flashsale-menu-account__logout">
						<RelativeLink to={{ pathname: "/" }} onClick={handleLogout}>
							<IconLogout height={20} style={{ marginRight: "10px" }} />
							<FormattedMessage id="menu.item.logout" />
						</RelativeLink>
					</div>
				)}
		</nav>
	);
};

FlashSaleMenuAccount.propTypes = {
	onItemClick: PropTypes.func,
	enableSponsorship: PropTypes.bool,
	enableCredits: PropTypes.bool,
	userIsConnected: PropTypes.bool,
	logout: PropTypes.func,
	isWhiteLabel: PropTypes.bool,
	strategy: strategyShape,
	partnercode: PropTypes.string,
	creditsAmount: PropTypes.number,
	creditNotesAmount: PropTypes.number,
	showCreditsNotification: PropTypes.bool,
	showCreditNotesNotification: PropTypes.bool,
	shouldDisplayCreditNotesEntry: PropTypes.bool,
	hideCreditNotification: PropTypes.func,
	hideCreditNoteNotification: PropTypes.func,
	showCustomerRequestNotification: PropTypes.bool,
};

FlashSaleMenuAccount.defaultProps = {
	hideCreditNoteNotification: () => {},
	hideCreditNotification: () => {},
	onItemClick: () => {},
	logout: () => {},
};

export default React.memo(FlashSaleMenuAccount);
