import React from "react";
import PropTypes from "prop-types";
import IconAwesomeLock from "app/pages/.shared/IconAwesomeLock";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";
import "./SignupMenuAccount.scss";
import { withRouter } from "react-router-dom";
import SvgIconMagnifyingGlassBold from "app/pages/.shared/IconMagnifyingGlassBold";
import {
	sendTagOnMenuGoToAuth,
	sendTagOnMenuGoToHelp,
	sendTagOnMenuGoToSdpSearch,
	sendTagOnMenuGoToSearchBooking,
} from "app/utils/analytics";

const SignupMenuAccount = ({ onItemClick, location }) => {
	return (
		<div className="signup-menu-account">
			<div className="signup-menu-account__signup-intro">
				<IconAwesomeLock height={20} width={20} />
				<FormattedMessage id="menu.mobile.signup.to.flashsales.intro" tagName="div" />
			</div>
			<nav className="signup-menu-account__body">
				<ul className="signup-menu-account__nav">
					<li className="signup-menu-account__nav-item signup-menu-account__nav-item--bold">
						<RelativeLink
							to={{ pathname: "/" }}
							onClick={() => {
								sendTagOnMenuGoToSdpSearch();
								onItemClick();
							}}
						>
							<SvgIconMagnifyingGlassBold
								height={20}
								width={20}
								style={{ marginRight: "10px" }}
							/>
							<FormattedMessage id="menu.item.search.trip" />
						</RelativeLink>
					</li>
					<li className="signup-menu-account__nav-item">
						<RelativeLink
							to={{
								pathname: "/booking-auth",
								search: `?onauthsuccess=${encodeURIComponent(
									location.pathname + location.search
								)}`,
							}}
							data-cy="go-to-booking-auth-link"
							onClick={() => {
								sendTagOnMenuGoToAuth();
								onItemClick();
							}}
						>
							<FormattedMessage id="menu.mobile.login.signup" />
						</RelativeLink>
					</li>
					<li className="signup-menu-account__nav-item">
						<RelativeLink
							to={{ pathname: "/booking-search" }}
							onClick={() => {
								sendTagOnMenuGoToSearchBooking();
								onItemClick();
							}}
							data-cy="go-to-booking-search-link"
						>
							<FormattedMessage id="menu.mobile.go.to.my.bookings" />
						</RelativeLink>
					</li>
					<li className="signup-menu-account__nav-item">
						<RelativeLink
							rel="noopener noreferrer"
							target="_blank"
							to={{ pathname: "/faq" }}
							onClick={() => {
								sendTagOnMenuGoToHelp();
								onItemClick();
							}}
						>
							<FormattedMessage id="menu.item.help" />
						</RelativeLink>
					</li>
				</ul>
			</nav>
		</div>
	);
};

SignupMenuAccount.propTypes = {
	onItemClick: PropTypes.func,
};

SignupMenuAccount.defaultProps = {
	onItemClick: () => {},
};

export default withRouter(SignupMenuAccount);
